.about-head{
    height: 600px;
    padding-top: 184px;
    box-sizing: border-box;
    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-08-04/aacdada91b000dec2eb6e813e8d294c4.png');
    background-size: 100% 100%;
    background-position: 50% 50%;
}

.company-img{
    width: 499px;
    height: 492px;
    margin-left: 30px;

    img{
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }
}

.about-footer{
    height: 564px;
    padding: 60px 0 50px;
    box-sizing: border-box;
    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-08-04/4c001347bd721e4c9ad68ebbd26f2e5c.png');
    background-size: 100% 100%;
    background-position: 50% 50%;
}