@import '@/mixin.scss';

.cooperation-banner {
    height: 600px;
    width: 100%;
    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-12-29/86bccec63649c0e50b3e6dac3d1d9a50.png');
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn {
        width: 158px;
        height: 40px;
        background: #0060AA;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        cursor: pointer;
    }
}

.forte-wrap {
    &.wrapper{
        margin-top: 90px;
    }
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .forte-item {
        width: 354px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 50px;

        .top {
            height: 193px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .bottom {
            height: 138px;
            padding: 18px 24px;
            box-sizing: border-box;
        }
    }
}