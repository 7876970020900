@import '@/mixin.scss';

.open-banner {
    height: 600px;
    width: 100%;
    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-12-09/bc3a9fe18ec1dfd441c39c6f6addab6b.png');
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn {
        width: 158px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        color: #001E92;
        font-weight: 500;
        cursor: pointer;
    }
}

.open-serve {
    height: 520px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .serve-item {
        width: 282px;
        height: 300px;
        box-sizing: border-box;
        padding: 58px 42px 0;
        background: #F3F2FF;
        border-radius: 10px;
        position: relative;
        transition: height .3s ease-in-out;

        .icon1 {
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/c2e3e41323079cc363d3e10c89259948.png', 64, 73);
        }

        .icon2 {
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/20dae010a3952ccecf11046687661d26.png', 64, 65);
        }

        .icon3 {
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/4758514e153f6486e4f6432584e0daed.png', 77, 65);
        }

        .icon4 {
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/245c7c1de053ac676e89ff7edc933a86.png', 64, 66);
        }

        &:hover {
            height: 380px;
            padding-top: 98px;
            color: #fff;
            background: #6236FF;
        }
    }
}

.open-api {
    background: #F5F5F5;

    .api-wrap {
        padding: 50px 0 78px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .api-col {
            width: 360px;
            height: 120px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 28px 0;
            display: flex;
            margin-top: 40px;
            box-sizing: border-box;

            .left {
                width: 68px;
                text-align: center;

                .icon {
                    display: inline-block;
                    width: 34px;
                    height: 34px;
                    background-size: cover;
                    background-position: 50% 50%;
                    vertical-align: middle;
                }
            }

            &:hover {
                box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

