@import '@/mixin.scss';
.educate{
    padding: 80px 0;

    .step{
        text-align: center;
        .step1-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/49c5897e8e1f1660b4cf4c5d99d388ca.png', 78, 78);
        }
        .step2-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/947a17e185f1488d2a48e5b1b492721d.png', 78, 78);
        }
        .step3-icon{    
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/10b18af9ac2161d6423c596a0a2c8ad2.png', 78, 78);
        }
        .step4-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/7b4241372c367123198df69273555931.png', 78, 78);
        }
        .step5-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/b435d4b55967c5622330f0bf4fef3a5c.png', 78, 78);
        }
        .step6-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/c329f16b76c3c7ecde5e780e65477594.png', 78, 78);
        }
      
        .you-icon{
            margin-top: 32px;
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/6c5a23ca0461a51f6da31183309badd8.png', 105, 15);
        }
    }

    .active-img1{
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/aca502a5d4821705135108bfae972409.png', 391, 378);
    }

    .active-img2{
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/909fd86505b61b5a4d347dc4b55cffa8.png', 508, 301);
    }

    .active-img3{
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/3fe8d117c43cf3141dacec201da3054e.png', 525, 338);
    }

    .active-img4{
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/8aab7c928c239dfe23ff622b1c425f34.png', 469, 361);
    }

    .active-img5{
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-08/bae1316e3a87575d0d8e0829640ffba7.png', 500, 351);
    }

    .active-detail{
        font-size: 16px;
        line-height: 30px;
        margin-top: 15px;
        width: 422px;
    }
}