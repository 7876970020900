@mixin Icon($url,$width,$height) {
    display: inline-block;
    width: #{$width}px;
    height: #{$height}px;
    background-image: url($url);
    background-size: cover;
    background-position: 50% 50%;
    vertical-align: middle;
  }

  