@import '@/mixin.scss';

.block-title {
    font-size: 26px;
    font-weight: 600;
    line-height: 37px;
    text-align: center;
}

.block-subtitle {
    font-size: 14px;
    color: #646566;
    line-height: 25px;
    text-align: center;
}

.product {
    padding: 80px 0 70px;

    .product-column {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 30px;
        height: 270px;


        .product-item {
            width: 347px;
            height: 250px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 30px;
            box-sizing: border-box;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2000);
            position: relative;
            transition: height .3s  ease-in-out;

            .top {
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                border-bottom: 1px solid #F5F5F5;

                img {
                    width: 45px;
                    height: 45px;
                }

                .title {
                    font-size: 18px;
                    margin-left: 20px;
                }
            }

            .bottom {
                .subtitle {
                    font-size: 14px;
                    margin: 20px 0 10px;
                }

                .content {
                    color: #818181;
                    font-size: 14px;
                    opacity: 1;
                    transition: all .3s ease-in-out;
                }
            }

            .btns {
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                left: 30px;
                width: 287px;
                transition: all .3s ease-in-out;
                opacity: 0;

                .btn-default,.btn-primary {
                    width: 123px;
                    height: 40px;
                    text-align: center;
                    line-height: 40px;
                    border: 1px solid #5D4CFE;
                    border-radius: 4px;
                }
                .btn-default{
                    color: #5D4CFE;
                   
                }
                .btn-primary{
                    color: #fff;
                    background: #5D4CFE;
                }
            }

            &:hover{
                cursor: pointer;
                height: 270px;


                .bottom{
                    .content {
                        opacity: 0;
                    }
                }

                .btns{
                    opacity: 1;
                    bottom: 30px;
                }
            }
        }
    }

}

.service {
    padding: 80px 0;

    .service-column {
        display: flex;
        border-radius: 4px;
        margin-top: 54px;
        overflow: hidden;
        background: #fff;

        .service-item {
            flex: 1;
            display: flex;
            flex-direction: column;

            .top {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: #fff;
                height: 97px;
                background: #5D4CFE;
                border-right: 1px solid #fff;

                .icon-1 {
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/12b8f7e35649f9012607f01a57df3523.png', 24, 24);
                }

                .icon-2 {
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/2c775ca8741ff8b35396d46b5383397f.png', 33, 24);
                }

                .icon-3 {
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/ea92212a746bc7315a52d2a6c38cfd63.png', 25, 24);
                }

                .icon-4 {
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/fa5d909554d8169aa0bc7f76ceba905b.png', 30, 24);
                }

                .icon-5 {
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/0c04cd108dd6ccdab6a7d05165f64357.png', 29, 24);
                }
            }

            .bottom {
                padding-top: 30px;
                padding-left: 30px;
                border-right: 1px solid #F5F5F5;
                flex: 1;

                .row {
                    padding-bottom: 20px;
                    font-size: 16px;
                    color: #818181;

                    .icon-gougou {
                        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/8e51be0f155632591468ef8ba4282e13.png', 16, 11);
                        margin-right: 8px;
                    }
                }
            }

            &:last-child {
                .top {
                    border: none;
                }

                .bottom {
                    border: none;
                }
            }


        }
    }
}

.brand {

    padding-top: 80px;

    .more {
        color: #5D4CFE;
        font-size: 14px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;

        .icon-you {
            margin-left: 6px;
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/5cdcf59e7ef8b110defb6586832f50c1.png', 8, 12);
        }
    }

    .brand-column {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 80px;

        .brand-item {
            width: 347px;
            height: 350px;
            background: #FFFFFF;
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2000);
            margin-bottom: 60px;

            .top {
                height: 160px;
                width: 347px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .bottom {
                padding: 0 20px 22px;

                .logo {
                    width: 82px;
                    height: 82px;
                    margin-top: -41px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }

                .title {
                    margin-top: 15px;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 25px;
                }

                .content {
                    font-size: 14px;
                    color: #646566;
                    line-height: 20px;
                }

            }
        }

    }
}

.information-box {
    height: 545px;
    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-08-02/82f1a5c4658e4ce3b4cff6a907052eb3.png');
    background-size: 100% 100%;
    background-position: 50% 50%;
}

.information {
    padding: 80px 30px;

    .information-column {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        .information-item {
            width: 160px;

            .icon-1 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-02/b661233e9b834d78aa3c50ac47395478.png', 44, 46);
            }

            .icon-2 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-02/2f756b135e0d7acdc73f60aa2781043e.png', 57, 46);
            }

            .icon-3 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-02/399081fa8642595f634289f3ea13fe1b.png', 44, 46);
            }

            .icon-4 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-02/e7be39bf43952f3c632d8a3a95a24405.png', 53, 46);
            }
        }
    }
}

