@import '@/mixin.scss';

 .header-box {
    height: 70px;
    background: #FFFFFF;

    .header{
        display: flex;
        align-items: center;
        height: 70px;
        font-size: 14px;

        .logo {
            width: 179px;
            height: 36px;
            margin-right: 56px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .nav-list {
        display: flex;
    
        .nav-item {
            height: 70px;
            line-height: 70px;
            margin-right: 40px;
            position: relative;

            .subnav_container{
                box-shadow: rgba(0 ,33 ,71 , 0.07) 0px 12px 48px 0px;
                position: absolute;
                top: 70px;
                left: 0;
                background: #fff;
                padding: 30px;
                z-index: 10;
                display: flex;
                visibility: hidden;
                opacity: 0;
                transform: translateY(-5%);
                border-radius: 0 0 3px 3px;

                img{
                    width: 46px;
                    height: 46px;
                }

                .subnav-1{
                    width: 320px;
                }
                .subnav-2{
                    width: 280px;
                    margin-left: 60px;
                }
                .title{
                    font-size: 14px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid #EBEDF0;
                }
            }

            &:hover{
                .subnav_container{
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                    transform: translate(0);
                    transition: transform 150ms cubic-bezier(0.48, 0.33, 0.24, 0.95) 0ms, opacity ease 100ms;
                }
            }


        }
    }

    .login {
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;

        .login-btn {
            width: 112px;
            height: 38px;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 38px;
            background: #5D4CFE;
            margin-left: 15px;
        }
    }

    .icon-xia {
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-01/e393472067d04feb34bcea34cbb48977.png', 8, 4);
    }

}