.banner-comp {
    height: 600px;

    .banner-item {
        width: 100%;
        height: 600px;
        background-size: cover;
        background-position: 50% 50%;
        padding-top: 157px;
        box-sizing: border-box;

        .moer-btn {
            width: 132px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            border-radius: 4px;
            margin-top: 30px;
            font-size: 16px;
        }
    }

    .banner-pagination{
        width: 40px;
        height: 8px;
        display: inline-block;
        margin: 0 5px;
        border-radius: 4px;
        background: rgba(0,0,0,.1);
    }
    .banner-pagination-active{
        background: rgba(85,136,247,.8);
    }

}