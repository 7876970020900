.solution-box {
    background-size: cover;
    background-position: 50% 50%;
    height: 545px;

    .solution {
        padding: 80px 0;
        color: #fff;

        .tab-menus {
            width: 236px;
            padding: 18px 12px;
            backdrop-filter: blur(14px);
            background: rgba(0, 0, 0, 0.5);
            border-radius: 8px 0 0 8px;

            .tab-menu {
                width: 212px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                border-radius: 4px;
                cursor: pointer;

                &.active {
                    background: #5D4CFE;
                }
            }
        }

        .solution-container {
            flex: 1;
            backdrop-filter: blur(14px);
            background: rgba(23, 24, 27, .4);
            border-radius: 0 8px 8px 0;
            padding: 40px 60px 0;
            display: flex;
            flex-direction: column;

            .btn-dufault,
            .btn-primary {
                width: 104px;
                height: 40px;
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }

            .btn-dufault {
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                margin-right: 20px;
            }

            .btn-primary {
                background: #5D4CFE;
            }

            .icon-groups {
                display: flex;
                text-align: center;
                font-size: 12px;
                height: 100%;
                align-items: center;
                justify-content: space-between;

                .icon-item {
                    min-width: 48px;
                }

                img {
                    width: auto;
                    height: 28px;
                }
            }
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        background: url('https://img.cxkoo.com/chengxuan/1/2022-08-02/a5b15a139185aba362720e92dc4d78f5.png') no-repeat -5000px -5000px, url('https://img.cxkoo.com/chengxuan/1/2022-08-02/bb3dcb424da0f67bd3fb88152858e1e1.png') no-repeat -5000px -5000px, url('https://img.cxkoo.com/chengxuan/1/2022-08-02/eb1009f89135443eba7767835397093e.png') no-repeat -5000px -5000px, url('https://img.cxkoo.com/chengxuan/1/2022-08-02/cbf2ed7bb2c24c75b2f5a730b2e5a884.png') no-repeat -5000px -5000px, url('https://img.cxkoo.com/chengxuan/1/2022-08-02/828ce7a4c65d4064c6ef074586a971e3.png') no-repeat -5000px -5000px;
    }
}