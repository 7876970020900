@import '@/mixin.scss';
.app{
  min-width: 1160px;
  background: #FFF;
}
.wrapper{
  width: 1160px;
  margin: 0 auto;
}

.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.fs-16{
  font-size: 16px;
}
.fs-18{
  font-size: 18px;
}
.fs-20{
  font-size: 20px;
}
.fs-24{
  font-size: 24px;
}
.fs-26{
  font-size: 26px;
}
.fs-30{
  font-size: 30px;
}
.fs-34{
  font-size: 34px;
}
.fs-38{
  font-size: 38px;
}
.fs-40{
  font-size: 40px;
}
.fs-48{
  font-size: 48px;
}

.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}


.lh1-5{
  line-height: 1.5;
}
.lh2{
  line-height: 2;
}

.tac{
  text-align: center;
}
.tal{
  text-align: left;
}

.indent2{
  text-indent: 2em;
}

.text-white{
  color: white;
}
.text-c999{
  color: #999;
}
.text-c000{
  color: #000;
}
.text-c484848{
  color: #484848;
}
.text-c646566{
  color: #646566;
}
.text-c5d4cfe{
  color: #5D4CFE;
}
.text-c262e90{
  color: #262E90;
}


.bg-f5{
  background: #f5f5f5;
}
.bg-5d4cfe{
  background: #5D4CFE;
}

.mgt10{
  margin-top: 10px;
}
.mgt15{
  margin-top: 15px;
}
.mgt20{
  margin-top: 20px;
}
.mgt30{
  margin-top: 30px;
}
.mgt40{
  margin-top: 40px;
}
.mgt50{
  margin-top: 50px;
}
.mgt60{
  margin-top: 60px;
}
.mgt65{
  margin-top: 65px;
}

.mgl10{
  margin-left: 10px;
}

.mgb10{
  margin-bottom: 10px;
}

.mgb20{
  margin-bottom: 20px;
}
.mgb30{
  margin-bottom: 30px;
}

.mgl20{
  margin-left: 20px;
}
.mgl30{
  margin-left: 30px;
}
.mgl-auto{
  margin-left: auto;
}

.mgr30{
  margin-right: 30px;
}
.mgr120{
  margin-right: 120px;
}

.pdl30{
  padding-left: 30px;
}
.pdr60{
  padding-right: 60px;
}

.pdt10{
  padding-top: 10px;
}
.pdt80{
  padding-top: 80px;
}
.pdb60{
  padding-bottom: 60px;
}
.pdb80{
  padding-bottom: 80px;
}

.text_link,a{
  cursor: pointer;
  &:hover{
    color: #5D4CFE;
  }
}


.flex{
  display: flex;
  
}
.flex1{
  flex: 1;
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}

.align-center{
  align-items: center;
}



.process {
  padding: 80px 0;

  &.bg-f5{
    background: #f5f5f5;
  }

  .row {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .icon1{
          @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/199a6e8aaeabbf1e99b4e154c00ad852.png",41,49);
      }
      .icon2{
          @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/66721c24de3d4548027bf35f33a0d0f2.png",41,48);
      }
      .icon3{
          @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/e50475e4aee089bdebddc86fa9e46be4.png",41,35);
      }
      .icon4{
          @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/aed7a6408251ac877b755f199734eb9a.png",48,48);
      }

      .you-icon{
          @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/4ad55238e00a1f232a8bfa71632bc4c7.png",48,21);
          margin: 0 38px;
          position: relative;
          top: -12px;
      }
  }

  .btn {
      width: 158px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #5D4CFE;
      color: #5D4CFE;
      cursor: pointer;
  }
}

