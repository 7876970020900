.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    .modal-content {
        width: 500px;
        height: 500px;
        border-radius: 10px;
        background: #fff;

        .modal-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            border-bottom: 1px solid #eee;

            .close-icon {
                width: 20px;
                height: 20px;
                background-image: url('https://img.cxkoo.com/chengxuan/1/2022-12-12/3ecba06317277b0d9aa7aa2aa1f86654.png');
                background-size: contain;
                background-position: 50% 50%;
                cursor: pointer;
            }
        }

        .modal-body {
            padding-top: 60px;

            img {
                width: 240px;
                height: 240px;
            }
        }
    }
}