@import '@/mixin.scss';

.gift {
    button {
        width: 158px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
    }
}

.footer{

    .qr_code{
        width: 90px;
        height: 90px;
        border-radius: 4px;
    }

    .copyright{
        margin-top: 50px;
        border-top: 1px solid #EBEDF0;

        .copyright-icon{
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-02/5ba9105f54d9dc0543f35b83e8cdee6d.png', 18, 20);
        }
    }
}

