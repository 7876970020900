@import '@/mixin.scss';

.channels {
    padding: 80px 0 40px;

    .grow {
        margin-top: 90px;
        display: flex;
        justify-content: space-around;

        .col {
            .shang-icon {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-07/24763853d46484f180ba2f2179333d7f.png', 22, 27);
                margin-right: 4px;
            }
        }
    }

    .tags {
        display: flex;
        justify-content: space-around;

        .tag {
            min-width: 139px;
            padding: 8px 22px;
            text-align: center;
            background: #F3F2FF;
            border: 1px dotted #5D4CFE;
            color: #5D4CFE;
            box-sizing: border-box;

            // background-image: linear-gradient(to right, #5D4CFE 0%, #5D4CFE 50%, transparent 0%);
            // background-size: 8px 1px;
            // background-repeat: repeat-x;

        }
    }

    .main_bg_1 {
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-07/7ca9da7666457491ff6254a8e0e92f4d.png', 1160, 847);
        display: block;
    }

    .main_bg_2 {
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-07/809f263afae4443f2d01a3e53dd6427c.png', 1160, 343);
        display: block;
    }

    .main_bg_3 {
        @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-07/bad70ee1125384701442ef1ff79106ad.png', 1160, 525);
        display: block;
    }
}