@import '@/mixin.scss';
.sidebar{
    position: fixed;
    width: 78px;
    right: 50px;
    bottom: 10%;
    z-index: 999;


    .contact-button {
        width: 78px;
        padding: 10px 0;
        box-sizing: border-box;
        background: #5D4CFE;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        text-align: center;
        color: #fff;
        font-size: 13px;
        margin-bottom: 12px;
        cursor: pointer;
    
        .contact-wxmp{
            position: absolute;
            top: -74px;
            left: 0;
            background-color: #fff;
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-12/7bf310ffce540678f945ea7615fadfff.png', 78, 78);
        }

        &.contact-wx{
            height: 78px;

            .contact-block{
                position: absolute;
                left: -290px;
                bottom: 0px;
                box-shadow: 0 2px 8px 0 rgba(0 ,0 ,0, .1);
                border-radius: 8px;
                opacity: 0;
                visibility: hidden;
                padding: 20px;
                background: #fff;
                .wx-code{
                    @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-12/786cb2f0d84beaad569c94336083d296.png', 230, 230);
                }
            }
        }

         &.contact-phone{
            height: 78px;

            .contact-block{
                position: absolute;
                left: -246px;
                bottom: 0px;
                box-shadow: 0 2px 8px 0 rgba(0 ,0 ,0, .1);
                border-radius: 8px;
                padding: 20px;
                background: #fff;
                opacity: 0;
                visibility: hidden;
            }
        }

        &:hover{
            .contact-block{
                opacity: 1;
                visibility: visible;
            }
        }

        .icon-wx{
            margin: 0 auto 5px;
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-04/0e9539a1d0a9eefaa3dbef0ea4c61997.png', 25, 20);
        }

        .icon-phone{
            margin: 0 auto 5px;
            @include Icon('https://img.cxkoo.com/chengxuan/1/2022-08-04/9a333afe915b19d6ba576b5f0b19d22d.png', 20, 20);
        }
       
    }
}



